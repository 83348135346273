import { AppProps } from 'next/app';
import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AppHead } from '@/components/AppHead';
import useAppStateInit, { AppStateProvider } from '@/hooks/useAppState';
import { Layout } from '@/components/Layout';
import { isProd } from '@lib/utils';
import zh from '../../lang/zh.json';
import en from '../../lang/en.json';
import '@/styles/global.scss';

const colors = {};

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const theme = extendTheme({ colors, breakpoints });

const clarityCode = `
(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "b753k0oyxf");
`;

export default function MyApp({ Component, pageProps }: AppProps) {
  const ctxValue = useAppStateInit();
  const router = useRouter();

  const referralCode = router.query.referralCode as string;
  const [appState, setAppState] = ctxValue;

  const { locale } = useRouter();

  useEffect(() => {
    if (referralCode && appState.referralCode !== referralCode) {
      setAppState({
        referralCode,
      });
    }
  }, [referralCode]);

  return (
    <IntlProvider
      messages={locale === 'zh' ? zh : en}
      locale={locale === 'zh' ? 'zh' : 'en'}
      defaultLocale="en"
    >
      <AppHead />
      <AppStateProvider value={ctxValue}>
        <ChakraProvider theme={theme}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ChakraProvider>
      </AppStateProvider>
      {isProd && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: clarityCode }}
        />
      )}
    </IntlProvider>
  );
}

import { useIntl } from 'react-intl';
import Head from 'next/head';

export function AppHead(props: {}) {
  const intl = useIntl();
  const siteTitle = intl.formatMessage({
    defaultMessage: 'Discord自动聊天机器人 - Discord智能聊天助手',
  });
  const siteDesc = intl.formatMessage({
    defaultMessage:
      '提供最专业最好用的 Discord 自动聊天机器人，支持智能防踢防抓、自动发言、自动回复、自动刷经验刷等级等功能。对普通用户非常友好操作简单开箱即用，轻松挂机刷等级。更有更多功能正在开发中，欢迎使用',
  });
  return (
    <Head>
      <title>{siteTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={siteDesc} />
      <meta
        name="keywords"
        content={intl.formatMessage({
          defaultMessage:
            'Discord,机器人,自动发言,自动回复,自动刷等级,自动划水聊天机器人,自动聊天机器人,自动肝白,刷NFT白名单,刷经验等级,防踢,模拟真人聊天,NFT,白名单,币圈,肝白',
        })}
      />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:url" content="https://discordsuperman.xyz/" />
      <meta
        property="og:site_name"
        content={intl.formatMessage({
          defaultMessage: 'Discord 智能聊天助手',
        })}
      />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:description" content={siteDesc} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:image" content="/assets/discord-superman-logo.png" />
      <meta name="twitter:site" content="@Discord智能聊天助手" />
      <meta name="twitter:creator" content="@DiscordSuperMan" />
    </Head>
  );
}

export function jsonResponse(status: number, data: any, init?: ResponseInit) {
  return new Response(JSON.stringify(data), {
    ...init,
    status,
    headers: {
      ...init?.headers,
      'Content-Type': 'application/json',
    },
  });
}

export const isProd = process.env.NODE_ENV === 'production';
export const ethErrorCodeMap: Record<string, any> = {
  32000: 'Insufficient account balance（账户余额不足）',
  '-32603': 'Insufficient account balance（账户余额不足）',
  INSUFFICIENT_FUNDS: 'Insufficient account balance（账户余额不足）',
  4001: 'User refused to sign',
};

export function isAddressEqual(a: string, b: string) {
  return a.toLowerCase() === b.toLowerCase();
}

export function ensureLowerCaseAddress<T extends Record<string, any>>(
  record: T,
): T {
  const result: T = Object.assign({}, record);

  Object.keys(record).forEach((key: keyof T) => {
    const value = record[key];
    if (typeof value === 'string' && value.length === 42) {
      if (isEthAddress(value)) {
        result[key] = value.toLowerCase() as T[keyof T];
      }
    }
  });

  return result;
}

function isEthAddress(address: string) {
  return /^0x[0-9a-fA-F]{40}$/.test(address);
}

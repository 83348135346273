import styles from '@/styles/Home.module.scss';
import { ReactNode } from 'react';
import Link from 'next/link';
import { Box, Select } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import { useCookie } from 'react-use';

export function Layout(props: { children: ReactNode }) {
  const intl = useIntl();
  const { locale, push, pathname, query, asPath } = useRouter();
  const [__, setNextLocalCookie] = useCookie('NEXT_LOCALE');
  return (
    <main className={styles.main}>
      <Link href="/">
        <Box cursor="pointer" mb={3}>
          <img
            className={styles.logo}
            src="/assets/discord-superman-logo.png"
            alt="Logo"
          />
          <h1 className={styles.title}>
            {intl.formatMessage({
              defaultMessage: 'Discord 智能聊天助手',
              id: 'title',
            })}
          </h1>
        </Box>
      </Link>
      {props.children}

      <Select
        mt={10}
        mb={10}
        size="sm"
        value={locale}
        onChange={(evt) => {
          const nextLocale = evt.target.value;
          if (!nextLocale) {
            return;
          }
          // 设置 cookie 为了让下次打开页面时能够继续使用这里切换的语言。默认情况下 NextJS 会将默认 locale 切换到不带 /locale 的路径，这样会导致下次打开页面时，语言偏好恢复原样
          setNextLocalCookie(nextLocale);
          // change just the locale and maintain all other route information including href's query
          push({ pathname, query }, asPath, { locale: nextLocale });
        }}
      >
        <option value="en">English</option>
        <option value="zh">中文</option>
      </Select>
    </main>
  );
}

import { useSetState } from 'react-use';
import { createContext, SetStateAction, useContext } from 'react';

interface AppState {
  referralCode?: string;
}

type CtxValue = ReturnType<typeof useAppStateInit>;

const appStateCtx = createContext<CtxValue>([{}, (prevState) => prevState]);

export const AppStateProvider = appStateCtx.Provider;

export function useAppState(): CtxValue {
  return useContext(appStateCtx);
}

export default function useAppStateInit() {
  return useSetState<AppState>({});
}
